// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-oferta-bukiety-js": () => import("./../../../src/pages/oferta/bukiety.js" /* webpackChunkName: "component---src-pages-oferta-bukiety-js" */),
  "component---src-pages-oferta-florystyka-slubna-js": () => import("./../../../src/pages/oferta/florystyka-slubna.js" /* webpackChunkName: "component---src-pages-oferta-florystyka-slubna-js" */),
  "component---src-pages-oferta-florystyka-zalobna-js": () => import("./../../../src/pages/oferta/florystyka-zalobna.js" /* webpackChunkName: "component---src-pages-oferta-florystyka-zalobna-js" */),
  "component---src-pages-oferta-flower-boxy-js": () => import("./../../../src/pages/oferta/flower-boxy.js" /* webpackChunkName: "component---src-pages-oferta-flower-boxy-js" */),
  "component---src-pages-oferta-wypozyczalnia-js": () => import("./../../../src/pages/oferta/wypozyczalnia.js" /* webpackChunkName: "component---src-pages-oferta-wypozyczalnia-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */)
}

